@import "bootstrap/dist/css/bootstrap.min.css";
@import "https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.4.2/css/all.css";
@import "../node_modules/flexlayout-react/style/dark.css";
@import "../node_modules/flexlayout-react/style/gray.css";

.dev {
  --environment-indicator: darkred;
  --environment-indicator-text: white;
  --environment-indicator-visibility: visible;
}

.qa {
  --environment-indicator: darkgoldenrod;
  --environment-indicator-text: white;
  --environment-indicator-visibility: visible;
}

.demo {
  --environment-indicator: darkgreen;
  --environment-indicator-text: white;
  --environment-indicator-visibility: visible;
}

.prod {
  --environment-indicator: #344557;
  --environment-indicator-text: white;
  --environment-indicator-visibility: hidden;
}

.light-theme {
  /*Body*/
  --theme-background: #eef2ff; /* Subtle indigo tint for background */
  --theme-secondary-background: #ffffff; /* Pure white for content */
  --theme-text: #1e293b; /* Rich slate for primary text */
  --theme-secondary-text: #475569; /* Balanced slate for secondary text */
  /*Navbar*/
  --theme-header: #2d3e50;
  --theme-nav-dropdown-text: #1e293b;
  /*Scrollbars*/
  --theme-scrollbar-track: #e2e8f0;
  --theme-scrollbar-thumb: #94a3b8;
  /*Modals*/
  --theme-modal-text: #1e293b;
  /*Tables*/
  --theme-table-header: #2d3e50;
  --theme-table-header-text: white;
  --theme-table-text: #1e293b;
  --theme-table-bold-text: #0f172a;
  --theme-table-odd-row: #cbd5e1; /* Darker gray for odd rows */
  --theme-table-even-row: #e2e8f0; /* Light gray for even rows */
  --theme-table-summary-row: #899fa3;
  --theme-table-summary-row-text: #0f172a;
  --theme-table-hover: #899fa3;
  --theme-table-hover-text: #0f172a;
  /*Buttons*/
  --theme-button: #6c757d;
  --theme-button-text: white;
  --theme-button-hover: #555;
  --theme-button-warning-hover: #dc3545;
  --theme-selector-hover: #083b88;
  /*Form Controls*/
  --theme-form-control: #212529;
  --theme-form-control-text: #adb5bd;
  --theme-form-control-border: #495057;
  --theme-form-control-disabled: #212529;
  --theme-form-control-highlight: #6ea8fe;
}

.dark-theme {
  /*Body*/
  --theme-background: black;
  --theme-secondary-background: #181818;
  --theme-text: #777;
  --theme-secondary-text: #777;
  /*Navbar*/
  --theme-header: #212529;
  --theme-nav-dropdown-text: #ffffff8c;
  /*Scrollbars*/
  --theme-scrollbar-track: #333;
  --theme-scrollbar-thumb: #777;
  /*Modals*/
  --theme-modal-text: #eeeeee;
  /*Tables*/
  --theme-table-header: #212529;
  --theme-table-header-text: white;
  --theme-table-text: #aaa;
  --theme-table-bold-text: white;
  --theme-table-odd-row: #333;
  --theme-table-even-row: #212529;
  --theme-table-summary-row: #212529;
  --theme-table-summary-row-text: white;
  --theme-table-hover: #111;
  --theme-table-hover-text: #4293a4;
  /*Buttons*/
  --theme-button: #6c757d;
  --theme-button-text: white;
  --theme-button-hover: #555;
  --theme-button-warning-hover: #dc3545;
  --theme-selector-hover: #083b88;
  /*Form Controls*/
  --theme-form-control: #212529;
  --theme-form-control-text: #adb5bd;
  --theme-form-control-border: #495057;
  --theme-form-control-disabled: #212529;
  --theme-form-control-highlight: #6ea8fe;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*******************************************************************************/
/*                                    THEMES                                  */
/*******************************************************************************/
body {
  background-color: var(--theme-background);
  color: var(--theme-text);
}

/* Override FlexLayout theme colors based on our theme class */
.dark-theme .flexlayout__layout {
  /* Dark theme overrides */
  --color-text: var(--theme-text);
  --color-background: var(--theme-background);
  --color-tabset-background: var(--theme-background);
  --color-base: var(--theme-secondary-background);
  --color-1: var(--theme-table-header);
  --color-2: var(--theme-table-odd-row);
  --color-3: var(--theme-table-even-row);
  --color-4: var(--theme-table-hover);
  --color-5: var(--theme-table-hover-text);
  --color-drag1: var(--theme-table-hover-text);
  --color-drag2: var(--theme-table-hover);
}

.light-theme .flexlayout__layout {
  /* Light theme overrides */
  --color-text: var(--theme-text);
  --color-background: var(--theme-background);
  --color-tabset-background: var(--theme-background);
  --color-base: var(--theme-secondary-background);
  --color-1: var(--theme-table-header);
  --color-2: var(--theme-table-odd-row);
  --color-3: var(--theme-table-even-row);
  --color-4: var(--theme-table-hover);
  --color-5: var(--theme-table-hover-text);
  --color-drag1: var(--theme-table-hover-text);
  --color-drag2: var(--theme-table-hover);
}

/* Ensure FlexLayout tabs have the correct background color */
.flexlayout__tab {
  background-color: var(--theme-background) !important;
}

/* Ensure FlexLayout tab headers are visible */
.flexlayout__tabset_tabbar_outer {
  background-color: var(--theme-secondary-background) !important;
  border-bottom: 1px solid var(--theme-table-header) !important;
}

/*******************************************************************************/
/*                                 Scroll Bars                                 */
/*******************************************************************************/
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: var(--theme-scrollbar-track);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--theme-scrollbar-thumb);
}

.always-show-scrollbar {
  overflow-x: scroll;
  overflow-y: auto;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: var(--theme-scrollbar-thumb) var(--theme-scrollbar-track); /* For Firefox */
  -webkit-overflow-scrolling: touch;
}

/* Specifically target the horizontal scrollbar */
.always-show-scrollbar::-webkit-scrollbar-horizontal {
  height: 6px;
}

.always-show-scrollbar::-webkit-scrollbar-thumb:horizontal {
  background-color: var(--theme-scrollbar-thumb);
  visibility: visible;
  min-width: 30px;
}

.always-show-scrollbar::-webkit-scrollbar-track:horizontal {
  background-color: var(--theme-scrollbar-track);
  visibility: visible;
}

/*******************************************************************************/
/*                                    GENERAL                                  */
/*******************************************************************************/
.div-window {
  display: flex;
  flex-flow: column;
  height: 100vh;
}

.div-window .div-header {
  flex: 0 1 auto;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.div-window .div-main {
  flex: 1 1 auto;
  overflow-y: auto;
}

.div-window .div-footer {
  flex: 0 1 70px;
  min-height: 70px;
}

.clickable {
  cursor: pointer;
}

.loading-container {
  display: flex;
  align-items: center; /* Vertically center the content inside .loading-container */
  justify-content: center; /* Horizontally center the content inside .loading-container */
  height: 100%; /* Optional: Adjust this based on your needs */
}

.flex-container {
  position: relative;
  height: 100%;
  overflow-y: auto;
}

.flexlayout__tab::-webkit-scrollbar {
  width: 12px;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

.no-border {
  border: transparent;
}

.no-background {
  background: transparent;
}
/*******************************************************************************/
/*                                    MODALS                                    */
/*******************************************************************************/
.modal-content {
  color: var(--theme-modal-text);
}

/*******************************************************************************/
/*                                    HEADER/NAV                               */
/*******************************************************************************/
.environment-indicator-container {
  border-top: 2px solid var(--environment-indicator);
  visibility: var(--environment-indicator-visibility);
}

.environment-indicator-container .environment-indicator {
  background-color: var(--environment-indicator);
}

.environment-indicator-container {
  display: flex;
  width: 100%;
  z-index: 10;
  position: absolute;
}

.environment-indicator-container .environment-indicator {
  padding: 0 10px;
  color: var(--environment-indicator-text);
  font-weight: 600;
  text-transform: uppercase;
  top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin: -3px auto;
  font-size: 8pt;
}

.navbar > .container {
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.navbar {
  margin: 0;
  padding: 0;
}

nav {
  background-color: var(--theme-header);
  font-weight: 600;
}

.dropend > .dropdown-toggle {
  color: var(--theme-nav-dropdown-text) !important;
  font-weight: 400;
}

.navbar-brand img {
  height: 40px;
  /* margin: 10px 0 10px 15px; */
}

.nav-icon {
  font-size: 24pt;
}

.nav-menu-icon {
  font-size: 14pt;
}

/*******************************************************************************/
/*                                    TICKER                                   */
/*******************************************************************************/
.ticker-container {
  position: fixed;
  bottom: 0;
  min-width: 100%;
}

/*fix bug in package that's making the ticker 50px tall by default*/
.styles_ticker__-0UvZ {
  height: 70px;
  background-color: var(--theme-secondary-background);
}

/*fix bug in package that's making the ticker not scroll continuously*/
.styles_ticker__-0UvZ > div {
  width: 50%;
}

/*widen ticker items to make room for longer names*/
.styles_tickerItemFinancial__m6Lt7 {
  width: 250px !important;
}

.styles_tickerTopLevel__vRrjN {
  color: var(--theme-text);
}

.styles_tickerItem__Vj57z:hover {
  background-color: var(--theme-secondary-background);
}

/*******************************************************************************/
/*                                    TABS                                     */
/*******************************************************************************/
.nav-tabs {
  --bs-bg-opacity: 1;
  border: 0px;
  display: flex;
  flex-direction: row; /* Ensure horizontal layout */
  width: 100%; /* Ensure tabs container takes full width */
  padding-bottom: 5px; /* Add space between tabs and content */
}

/* More aggressive fix for tabs appearing vertically */
.nav-dark.nav-tabs,
.nav-tabs.nav-dark {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  width: 100% !important;
  padding-bottom: 5px !important; /* Add space between tabs and content */
}

/* Universal styles for tab Nav.Item elements only */
.tabs-container .nav-item,
.nav-tabs .nav-item,
.nav-dark.nav-tabs .nav-item,
.nav-tabs.nav-dark .nav-item {
  float: left;
  display: inline-block !important;
  background-color: var(--theme-background) !important;
  cursor: pointer;
  flex: 1; /* Make each tab item take equal width */
  text-align: center;
  padding: 0 !important; /* Remove any padding */
  margin: 0 !important; /* Remove any margin */
  border: none !important;
  position: relative !important;
  overflow: visible !important;
}

/* Universal styles for tab Nav.Link elements only */
.tabs-container .nav-link,
.nav-tabs .nav-link,
.nav-dark.nav-tabs .nav-link,
.nav-tabs.nav-dark .nav-link {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0.5rem 1rem !important;
  margin: 0 !important; /* Remove any margin */
  border-radius: 0 !important; /* Remove any border radius */
  border: none !important;
  font-size: 1rem !important; /* Match FlexLayout tab font size */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important; /* Match FlexLayout tab font family */
}

/* Special classes for ensuring Nav.Link fills Nav.Item */
.nav-item-fill {
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  position: relative !important;
}

.nav-link-fill {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  border-radius: 0 !important;
  border: none !important;
  padding: 0.5rem 1rem !important;
}

/* Target the React Bootstrap tab structure specifically */
#admin-tabs {
  flex-direction: row !important;
}

#admin-tabs .nav-item {
  display: inline-block !important;
  float: left !important;
  flex: 1;
}

#admin-tabs .nav-link {
  display: inline-block !important;
  width: 100%;
}

/* FlexLayout-specific tab styling */
.flexlayout__tab_button {
  cursor: pointer !important;
  text-align: center !important;
  color: var(--theme-secondary-text) !important;
  font-weight: 600 !important;
  padding: 0.75rem 1.25rem !important;
  border: 0px !important;
  border-radius: 0px !important;
  transition: all 0.2s ease-in-out !important;
  background-color: var(--theme-secondary-background) !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  font-size: 1rem !important;
}

.flexlayout__tab_button:hover {
  color: var(--theme-table-hover-text) !important;
  background-color: var(--theme-table-hover) !important;
  opacity: 0.9 !important;
  box-shadow: none !important;
}

.flexlayout__tab_button--selected {
  color: var(--theme-table-hover-text) !important;
  /*background-color: #83979c !important; /* Medium version for light theme - between #899fa3 and #778e93 */
  background-color: var(
    --theme-secondary-background
  ) !important; /* Medium version for light theme - between #899fa3 and #778e93 */
  border: 0px !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  position: relative !important;
}

/* Dark theme override for selected FlexLayout tab */
.dark-theme .flexlayout__tab_button--selected {
  /*background-color: #0d0d0d !important; /* Medium version for dark theme - between #111 and #0a0a0a */
  background-color: var(
    --theme-background
  ) !important; /* Medium version for dark theme - between #111 and #0a0a0a */
}

.flexlayout__tab_button--selected::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--theme-table-hover-text) !important;
}

.flexlayout__tabset_tabbar_outer {
  background-color: var(--theme-secondary-background) !important;
  border: none !important;
}

.flexlayout__tabset_tabbar_inner {
  background-color: var(--theme-secondary-background) !important;
  border: none !important;
}

.flexlayout__tab_button_top {
  box-shadow: none !important;
  background-color: var(--theme-secondary-background) !important;
  border: none !important;
}

.flexlayout__tab_toolbar {
  background-color: var(--theme-secondary-background) !important;
  border-bottom: 1px solid var(--theme-table-header) !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 !important;
  margin: 0 !important;
  min-height: 41px !important; /* Match the height of tabset tabbar */
}

.flexlayout__tab_toolbar_button {
  cursor: pointer !important;
  text-align: center !important;
  color: var(--theme-secondary-text) !important;
  font-weight: 600 !important;
  border: 0px !important;
  border-radius: 0 !important;
  padding: 0.5rem 1rem !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out !important;
  background-color: var(--theme-secondary-background) !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  font-size: 1rem !important;
}

.flexlayout__tab_toolbar_button:hover {
  color: var(--theme-table-hover-text) !important;
  background-color: var(--theme-table-hover) !important;
  opacity: 0.9 !important;
}

.flexlayout__tab_toolbar_button--selected {
  color: var(--theme-table-hover-text) !important;
  background-color: var(
    --theme-secondary-background
  ) !important; /* #83979c !important; /* Medium version for light theme - between #899fa3 and #778e93 */
  border: 0px !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  position: relative !important;
}

/* Dark theme override for selected toolbar button */
.dark-theme .flexlayout__tab_toolbar_button--selected {
  /*background-color: #0d0d0d !important; /* Medium version for dark theme - between #111 and #0a0a0a */
  background-color: var(
    --theme-background
  ) !important; /* Medium version for dark theme - between #111 and #0a0a0a */
}

.flexlayout__tab_button_inner {
  /* Enhance inner button without changing core structure */
  cursor: pointer !important;
  text-align: center !important;
  background-color: transparent !important;
}

.flexlayout__tab_button_content {
  /* Enhance content alignment without changing core structure */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: transparent !important;
}

/* Match the exact styling of Nav.Link.active */
.flexlayout__tab_button--selected {
  color: var(--theme-table-hover-text) !important;
  background-color: var(--theme-table-hover) !important;
  font-weight: 600 !important;
  border: 0px !important;
  box-shadow: none !important;
  position: relative !important;
}

.flexlayout__tab_button--selected::after {
  content: "" !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 3px !important;
  background-color: var(--theme-table-hover-text) !important;
}

.flexlayout__tab_button:hover {
  color: var(--theme-table-hover-text) !important;
  border: 0px !important;
  background-color: var(--theme-table-hover) !important;
  opacity: 0.9 !important;
  box-shadow: none !important;
}

/* Ensure tab close buttons are visible */
.flexlayout__tab_button_trailing {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: transparent !important;
}

.flexlayout__tab_button_trailing:hover {
  background-color: rgba(255, 0, 0, 0.2) !important;
}

/* Fix for selected tab panel background */
.flexlayout__tabset
  .flexlayout__tabset_tabbar_outer
  .flexlayout__tabset_tabbar_inner
  .flexlayout__tab_button--selected {
  background-color: var(--theme-secondary-background) !important;
  color: var(--theme-table-hover-text) !important;
}

/* Fix for border bottom on selected tabs */
.flexlayout__tab_button--selected:after {
  content: "" !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 2px !important;
  background-color: var(--theme-table-hover-text) !important;
  display: none !important; /* Only enable if Bootstrap tabs have bottom border */
}

/* Fix for FlexLayout border button styling to match tabs */
.flexlayout__border_button {
  background-color: var(--theme-secondary-background) !important;
  color: var(--theme-secondary-text) !important;
  font-weight: 600 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  font-size: 1rem !important;
}

.flexlayout__border_button:hover {
  color: var(--theme-table-hover-text) !important;
  background-color: var(--theme-table-hover) !important;
  opacity: 0.9 !important;
}

.flexlayout__border_button--selected {
  color: var(--theme-table-hover-text) !important;
  background-color: var(--theme-secondary-background) !important;
  font-weight: 600 !important;
}

.flexlayout__border_button_content {
  color: var(--theme-secondary-text) !important;
}

.flexlayout__border_button--selected .flexlayout__border_button_content {
  color: var(--theme-table-hover-text) !important;
}

.flexlayout__popup_menu_container {
  background-color: var(--theme-secondary-background) !important;
  color: var(--theme-secondary-text) !important;
  border: 1px solid var(--theme-table-header) !important;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3) !important;
}

.flexlayout__popup_menu_item {
  color: var(--theme-secondary-text) !important;
}

.flexlayout__popup_menu_item:hover {
  background-color: var(--theme-table-hover) !important;
  color: var(--theme-table-hover-text) !important;
}

/* Additional FlexLayout elements that need theme variables */
.flexlayout__border_top {
  background-color: var(--theme-secondary-background) !important;
  border-bottom: 1px solid var(--theme-table-header) !important;
}

.flexlayout__border_bottom {
  background-color: var(--theme-secondary-background) !important;
  border-top: 1px solid var(--theme-table-header) !important;
}

.flexlayout__border_left {
  background-color: var(--theme-secondary-background) !important;
  border-right: 1px solid var(--theme-table-header) !important;
}

.flexlayout__border_right {
  background-color: var(--theme-secondary-background) !important;
  border-left: 1px solid var(--theme-table-header) !important;
}

.flexlayout__tabset_sizer {
  background-color: var(--theme-secondary-background) !important;
}

.flexlayout__tabset_header_sizer {
  background-color: var(--theme-secondary-background) !important;
}

.flexlayout__drag_rect {
  background-color: rgba(66, 147, 164, 0.3) !important;
  border: 1px solid var(--theme-table-hover-text) !important;
}

.flexlayout__outline_rect {
  border: 2px solid var(--theme-table-hover-text) !important;
}

.flexlayout__outline_rect_edge {
  background-color: var(--theme-table-hover-text) !important;
}

.flexlayout__edge_rect {
  background-color: var(--theme-table-hover-text) !important;
}

.flexlayout__edge_rect:hover {
  background-color: var(--theme-table-hover) !important;
}

.flexlayout__splitter_drag {
  background-color: var(--theme-table-hover-text) !important;
}

/* Remove duplicate styles */
.flexlayout__tab {
  background-color: var(--theme-background) !important;
}

.flexlayout__tab_content {
  background-color: var(--theme-background) !important;
}

/* Bootstrap-specific tab styling */
.tabs-container .nav-tabs .nav-item .nav-link,
.nav-tabs .nav-item .nav-link {
  font-weight: 600;
  color: var(--theme-secondary-text);
  font-size: 1rem !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  transition: all 0.2s ease-in-out !important;
  background-color: var(
    --theme-secondary-background
  ) !important; /* Explicit background color */
}

.tabs-container .nav-tabs .nav-item .nav-link.active,
.nav-tabs .nav-item .nav-link.active {
  color: var(--theme-table-hover-text) !important;
  background-color: var(
    --theme-secondary-background
  ) !important; /* #83979c !important; /* Medium version for light theme - between #899fa3 and #778e93 */
  border: 0px;
  font-weight: 600 !important;
  box-shadow: none !important;
  position: relative !important;
}

/* Dark theme override for active tab */
.dark-theme .tabs-container .nav-tabs .nav-item .nav-link.active,
.dark-theme .nav-tabs .nav-item .nav-link.active {
  /*background-color: #0d0d0d !important; /* Medium version for dark theme - between #111 and #0a0a0a */
  background-color: var(
    --theme-background
  ) !important; /* Medium version for dark theme - between #111 and #0a0a0a */
}

.tabs-container .nav-tabs .nav-item .nav-link.active::after,
.nav-tabs .nav-item .nav-link.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--theme-table-hover-text);
}

.tabs-container .nav-tabs .nav-item .nav-link:hover,
.nav-tabs .nav-item .nav-link:hover {
  color: var(--theme-table-hover-text) !important;
  border: 0px !important;
  background-color: var(--theme-background) !important;
  opacity: 1 !important; /* Full opacity to match selected tab */
  box-shadow: none !important;
  font-weight: 600 !important; /* Match selected tab font weight */
}

/* Fix for tab content display */
.tabs-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.tab-content {
  border-top: transparent;
  height: calc(100% - 41px) !important;
  display: block !important;
  overflow: auto;
}

.tab-content .tab-pane {
  height: 100%;
  display: none;
}

.tab-content .tab-pane.active {
  display: block !important;
}

.tab-content .tab-pane.show {
  display: block !important;
}

/*******************************************************************************/
/*                                    Tables                                   */
/*******************************************************************************/
/*////Base Table Styles////*/
table {
  white-space: nowrap;
  vertical-align: middle;
  width: 100%;
  font-weight: 400;
}

th {
  padding: 5px 5px 5px 5px;
}

td {
  padding: 5px 5px 5px 5px;
}

td.clickable:hover {
  font-weight: 600;
}

tr.clickable:hover > td {
  color: var(--theme-table-hover-text);
  font-weight: 600;
}

table.clickable tr:hover > td {
  color: var(--theme-table-hover-text);
  font-weight: 600;
}

td.clickable:hover {
  color: var(--theme-table-hover-text);
  background-color: var(--theme-table-hover);
}

table {
  color: var(--theme-table-text);
}

.table-bold {
  color: var(--theme-table-bold-text);
}

th {
  background-color: var(--theme-table-header);
  color: var(--theme-table-header-text);
}

tr:nth-child(odd) > td {
  background-color: var(--theme-table-odd-row);
}

tr:nth-child(even) > td {
  background-color: var(--theme-table-even-row);
}

.summary-row td {
  font-weight: 600;
}

table .summary-row td {
  background-color: var(--theme-table-summary-row);
  font-weight: 600;
  color: var(--theme-table-summary-row-text);
}

.centered {
  text-align: center;
}

.right-align {
  text-align: right;
}

.table-condensed th {
  padding-top: 2px;
  padding-bottom: 2px;
}

.table-condensed td {
  padding-top: 2px;
  padding-bottom: 2px;
}

.table-extra-small th {
  padding: 2px;
  font-size: 12px;
  font-weight: 600;
}

.table-extra-small tr > td {
  padding: 2px;
  font-size: 12px;
  font-weight: 400;
}

.market-grid td {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 1px;
}

.market-grid tbody {
  overflow: auto;
}

.market-grid tr:hover > td {
  color: unset;
}

.market-grid tr:nth-child(odd) > td {
  background-color: var(--theme-table-odd-row);
}

.market-grid tr:nth-child(even) > td {
  background-color: var(
    --theme-table-odd-row
  ); /*This is correct; we don't want to alternate on the market grid*/
}

tr.row-collapse > td {
  background-color: var(--theme-table-even-row);
  border: none;
}

tr.row-collapse {
  visibility: collapse;
}

tbody:has(> tr .queue-toggle:checked) tr.row-collapse {
  visibility: visible;
}

/*******************************************************************************/
/*                    Accordian Selector (Market Selector)                     */
/*******************************************************************************/
.AccordionBodyL1 {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.AccordionBodyL1 .accordion-button {
  padding-top: 20px;
  padding-right: 0px;
  padding-bottom: 20px;
  padding-left: 40px;
}

.AccordionBodyL1 .accordion-button[aria-expanded="true"] {
  /*   color: #9ec5ff;
  background-color:#020c1b; */
}

.AccordionBodyL2 {
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 20px;
}

.AccordionBodyL2 .list-group {
  padding-left: 30px;
}

.AccordionBodyL2 .list-group-item:hover {
  background-color: var(--theme-selector-hover);
  color: var(--theme-button-text);
}

/*******************************************************************************/
/*                                     Tiles                                   */
/*******************************************************************************/
.tile {
  border-style: solid;
  border-width: medium;
  border-radius: 10px;
}

.tile {
  background-color: var(--theme-secondary-background);
  border-color: var(--theme-background);
}

/*******************************************************************************/
/*                            Market Data Dashboard                            */
/*******************************************************************************/

.generate-marks-button {
}

.market-attribute-selector:hover {
  background-color: var(--theme-selector-hover);
}

.market-attribute-selector {
  text-align: center;
}

.market-data-graph {
  height: 300px;
}

.trades-table {
  margin-top: 10px;
}

.marks-table {
  margin-top: 10px;
}

.mark-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mark-cell.submitted {
  color: #b88603;
}

/*******************************************************************************/
/*                                    Pagination                               */
/*******************************************************************************/
.pagination {
  background-color: var(--theme-background);
}

.pagination > li > button {
  background-color: var(--theme-secondary-background);
  border-color: var(--theme-secondary-background);
  color: var(--theme-text);
}

pagination > li > button:focus,
.pagination > li > button:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: var(--theme-table-bold-text);
  font-weight: 600;
  background-color: var(--theme-table-hover);
  border-color: var(--theme-table-hover);
}

.pagination > .active > button .pagination > .active > button:hover {
  color: var(--theme-table-bold-text);
  font-weight: 600;
  background-color: var(--theme-table-odd-row);
  border: solid 1px var(--theme-table-odd-row);
}

/*******************************************************************************/
/*                                    FORMS                                    */
/*******************************************************************************/
.form-group {
  padding-bottom: 8px;
}

.form-footnote {
  font-size: 12px;
  font-weight: 600;
  float: right;
}

.form-footnote {
  color: var(--theme-table-text);
}

.form-text-area {
  width: 100%;
  height: 50vh;
  resize: auto;
  font-size: 16px;
  padding: 10px;
  box-sizing: border-box;
  overflow: auto;
}

/*******************************************************************************/
/*                                    AUTH COMPONENTS                          */
/*******************************************************************************/
/* Add padding-top and margin-left to container-fluid elements within auth components */
.container-fluid,
[class*="admin/auth"] .container-fluid,
[id*="admin-auth"] .container-fluid {
  padding-top: 10px !important;
  padding-left: calc(var(--bs-gutter-x) * 0.5 + 5px) !important;
}

/*******************************************************************************/
/*                                    DUAL-LISTBOX                             */
/*    This is a 3rd party control, so have to emulate the bootstrap styling    */
/*******************************************************************************/
.react-dual-listbox {
  width: 100% !important;
  padding: 0.375rem !important;
}

.rdl-control {
  display: block !important;
  width: 100% !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  background-clip: padding-box !important;
  appearance: none !important;
  border-radius: 0.375rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.dark-theme .rdl-control {
  color: var(--theme-form-control-text) !important;
  background-color: var(--theme-form-control) !important;
  border: 1px solid var(--theme-form-control-border) !important;
}

.rdl-control:disabled {
  font-weight: 400 !important;
}

.dark-theme .rdl-control:disabled {
  color: var(--theme-form-control-text) !important;
  background-color: var(--theme-form-control-disabled) !important;
}

.rdl-filter {
  display: block !important;
  width: 100% !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  background-clip: padding-box !important;
  appearance: none !important;
  border-radius: 0.375rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.dark-theme .rdl-filter {
  color: var(--theme-form-control-text) !important;
  background-color: var(--theme-form-control) !important;
  border: 1px solid var(--theme-form-control-border) !important;
}

.dark-theme .rdl-filter:disabled {
  color: var(--theme-form-control-text) !important;
  background-color: var(--theme-form-control-disabled) !important;
}

.rdl-control > option {
  font-size: 1rem !important;
  font-weight: normal !important;
  display: block !important;
  min-height: 1.2em !important;
  padding: 0px 2px 1px !important;
}

.rdl-control:disabled > option {
  font-weight: 400 !important;
}

.dark-theme .rdl-control:disabled > option {
  color: var(--theme-form-control-text) !important;
}

.rdl-control:focus {
  border: 1px solid var(--theme-form-control-highlight) !important;
}

.rdl-filter:focus {
  border: 1px solid var(--theme-form-control-highlight) !important;
}

.rdl-control > option:checked {
  background-color: var(--theme-form-control-border) !important;
  color: var(--theme-form-control-text) !important;
}

.rdl-control:focus option:checked {
  background: linear-gradient(
    0deg,
    var(--theme-form-control-highlight) 0%,
    var(--theme-form-control-highlight) 100%
  ) !important;
  color: black !important; /*this just won't take...*/
}

.rdl-btn {
  font-weight: 400 !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: middle !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  border: 1px solid transparent !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  border-radius: 0.25rem !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  color: var(--theme-button-text) !important;
  background-color: var(--theme-button) !important;
  border-color: var(--theme-button) !important;
}

.rdl-hide-move-all .rdl-move-all-left {
  display: none !important;
}

.rdl-hide-move-all .rdl-move-all-right {
  display: none !important;
}

.rdl-btn:hover {
  background-color: var(--theme-button-hover) !important;
  border-color: var(--theme-button-hover) !important;
}

/*******************************************************************************/
/*                                    Multi-Select                             */
/*******************************************************************************/
.neutral-react-select-container .neutral-react-select__control {
  background-clip: padding-box !important;
}

.dark-theme .neutral-react-select-container .neutral-react-select__control {
  background-color: var(--theme-form-control) !important;
  border: 1px solid var(--theme-form-control-border) !important;
}

.dark-theme
  .neutral-react-select-container
  .neutral-react-select__control--is-focused {
  border: 1px solid var(--theme-form-control-highlight) !important;
}

.neutral-react-select-container .neutral-react-select__menu {
  background-clip: padding-box !important;
}

.dark-theme .neutral-react-select-container .neutral-react-select__menu {
  background-color: var(--theme-form-control);
  background-clip: padding-box !important;
  border: 1px solid var(--theme-form-control-border) !important;
}

.dark-theme .neutral-react-select-container .neutral-react-select__option {
  color: var(--theme-form-control-text) !important;
}

.dark-theme
  .neutral-react-select-container
  .neutral-react-select__option--is-selected {
  background-color: var(--theme-form-control-border) !important;
}

.dark-theme
  .neutral-react-select-container
  .neutral-react-select__option--is-focused {
  background-color: var(--theme-form-control-border) !important;
  color: var(--theme-form-control-text) !important;
}

.dark-theme
  .neutral-react-select-container
  .neutral-react-select__option--is-selected {
  background-color: var(--theme-form-control-highlight) !important;
  color: var(--theme-form-control) !important;
}

.neutral-react-select-container .neutral-react-select__indicator-separator {
}

.neutral-react-select-container .neutral-react-select__input-container,
.neutral-react-select-container .neutral-react-select__placeholder,
.neutral-react-select-container .neutral-react-select__single-value {
}

/*******************************************************************************/
/*                                    TOASTS                                   */
/*******************************************************************************/
.Toastify__toast-container {
  padding-top: 0px !important;
}

.Toastify__progress-bar-theme--dark {
  height: 1px !important;
  background-color: var(--theme-form-control-highlight) !important;
}

.Toastify__progress-bar-theme--light {
  height: 1px !important;
  background-color: var(--theme-form-control-highlight) !important;
}

.Toastify__toast-body {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-size: 15px !important;
  font-weight: 700 !important;
}

.Toastify__toast-body {
  color: var(--theme-secondary-text) !important;
}

.Toastify__toast-theme--dark {
  border: 1px solid transparent !important;
  border-radius: 10px !important;
  background-color: var(--theme-background) !important;
}

.Toastify__toast-theme--light {
  border: 1px solid transparent !important;
  border-radius: 10px !important;
  background-color: var(--theme-background) !important;
}

.Toastify__toast-theme--dark {
  border-color: var(--theme-form-control-highlight) !important;
}

.Toastify__toast-theme--light {
  border-color: var(--theme-form-control-highlight) !important;
}

.Toastify__toast-theme--dark.Toastify__toast--error {
  border-color: #eb5757 !important;
}

.Toastify__toast-theme--light.Toastify__toast--error {
  border-color: #eb5757 !important;
}

.Toastify__progress-bar--error {
  background-color: #eb5757 !important;
}

.Toastify__toast-theme--dark.Toastify__toast--warning {
  border-color: orange !important;
}

.Toastify__toast-theme--light.Toastify__toast--warning {
  border-color: orange !important;
}

.Toastify__progress-bar--warning {
  background-color: orange !important;
}

.Toastify__toast-theme--dark.Toastify__toast--success {
  border-color: green !important;
}

.Toastify__toast-theme--light.Toastify__toast--success {
  border-color: green !important;
}

.Toastify__progress-bar--success {
  background-color: green !important;
}

/*******************************************************************************/
/*                                    Buttons                                  */
/*******************************************************************************/
.btn-delete {
  background-color: var(--theme-button); /* Secondary color */
  color: var(--theme-button-text);
  transition: background-color 0.3s ease;
}

.btn-delete:hover {
  background-color: var(
    --theme-button-warning-hover
  ); /* Danger color on hover */
}

/* Fix for selected tab panel background */
.flexlayout__tabset
  .flexlayout__tabset_tabbar_outer
  .flexlayout__tabset_tabbar_inner
  .flexlayout__tab_button--selected {
  background-color: var(--theme-secondary-background) !important;
  color: var(--theme-table-hover-text) !important;
}
