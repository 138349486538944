/* App Layout Integration */
.app-sidebar {
  height: 100vh;
  display: flex;
  flex-shrink: 0;
}

/* Sidebar Layout */
.sidebar {
  width: auto;
  height: 100vh;
  background-color: var(--theme-background);
  border-right: 1px solid #333;
  transition: width 0.15s ease-out;
  overflow: visible; /* Change from hidden to visible to allow drawer to show */
  flex-shrink: 0;
  margin-right: -1px;
  will-change: width;
  position: relative; /* Create stacking context */
  z-index: 1000; /* Ensure sidebar and its children stack above main content */
}

/* Sidebar Navigation */
.sidebar-nav {
  position: relative;
  width: 250px;
  height: 100vh;
  background-color: #1a1a1a;
  transition: width 0.15s ease-out;
  z-index: 2;
  display: flex;
  flex-direction: column;
  overflow: visible; /* Change from hidden to visible */
  will-change: width;
}

.sidebar-nav.expanded {
  width: 250px;
}

.sidebar-nav.collapsed {
  width: 60px;
}

/* Sidebar Header - Fixed at top */
.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-bottom: 1px solid #333;
  flex-shrink: 0;
  /* Prevent header from shrinking */
}

.sidebar-brand {
  display: flex;
  align-items: center;
}

.toggle-container {
  display: flex;
  align-items: center;
  margin-left: 0rem;
  /* Add space between logo and button */
}

.toggle-button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
}

.toggle-button:hover {
  color: #007bff;
}

/* Scrollable Container - Includes both content and footer */
.sidebar-content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
  scrollbar-color: #666 #1a1a1a;
}

/* Main menu content */
.sidebar-menu {
  flex: 1;
}

/* Footer - Now part of scrollable content */
.sidebar-footer {
  border-top: 1px solid #333;
  margin-top: 1rem;
  flex-shrink: 0;
  /* Prevent footer from shrinking */
}

/* Footer nav items should have slightly more padding */
.sidebar-footer .nav-item {
  padding: 0.75rem 1rem;
}

/* Customize scrollbar for webkit browsers */
.sidebar-content::-webkit-scrollbar {
  width: 6px;
}

.sidebar-content::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.sidebar-content::-webkit-scrollbar-thumb {
  background-color: #666;
  border-radius: 3px;
}

/* Navigation Items */
.nav-item {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.15s ease-out;
  cursor: pointer;
  border: none;
  width: 100%;
  text-align: left;
  position: relative;
  border-left: 4px solid transparent;
  background-color: #1a1a1a;
  z-index: 1;
  font-weight: normal !important;
}

/* Button Items - Must never show active state */
.nav-item.button-item {
  background-color: transparent;
  border-left-color: transparent;
}

.nav-item.button-item:hover {
  background-color: #2d3f50;
}

.nav-item.button-item::after {
  display: none;
  /* Remove active indicator */
}

/* Regular Navigation Items */
.nav-item:not(.button-item):not(.active):not(.has-active-child):hover {
  background-color: #2d3f50;
}

.nav-item:not(.button-item).active:not(.submenu-trigger),
.nav-item:not(.button-item).active:not(.submenu-trigger):hover {
  background-color: #1e4976 !important;
  border-left-color: #007bff;
  transition: none !important;
}

/* Active State Styles */
.nav-item.active:not(.submenu-trigger):not(.button-item)::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #007bff;
}

/* Parent Highlighting - Required, Must Not Be Removed */
.nav-item.submenu-trigger.has-active-child {
  background-color: #2d3f50;
  border-left: 2px solid #3d8bdf;
}

/* Keep parent highlight when submenu is collapsed - Required */
.menu-section:has(.nav-item.active)>.nav-item.submenu-trigger {
  background-color: #2d3f50;
  border-left: 2px solid #3d8bdf;
}

/* Navigation Item Content */
.nav-item-content {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  min-height: 24px;
}

.nav-item-text {
  flex: 1;
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.2;
  padding: 2px 0;
}

.nav-item .fa {
  width: 20px;
  text-align: center;
  margin-right: 10px;
  margin-top: 3px;
}

/* Submenu Styles */
.submenu {
  overflow: hidden;
}

/* Submenu in expanded mode - should be part of the normal flow, not a drawer */
.sidebar-nav.expanded .submenu {
  position: static !important;
  display: block !important;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.25s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1) !important;
  will-change: opacity, max-height;
  transform: none !important;
  visibility: visible !important;
  pointer-events: none;
  background-color: inherit !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  left: auto !important;
  min-width: auto !important;
  overflow: hidden !important;
  padding-left: 0 !important; /* Remove padding from container to allow highlighting to full width */
}

/* Submenu expanded in expanded mode */
.sidebar-nav.expanded .menu-section.has-expanded-drawer .submenu {
  max-height: 2000px; /* Large enough to accommodate all content */
  opacity: 1;
  pointer-events: auto;
  transform: none !important;
}

/* Add indentation to submenu items in expanded mode */
.sidebar-nav.expanded .submenu .nav-item {
  padding-left: 40px !important; /* Add indentation to the items themselves */
}

/* Add indentation for second level of nesting */
.sidebar-nav.expanded .submenu .submenu .nav-item {
  padding-left: 60px !important; /* Additional indentation for second level */
}

/* Add indentation for third level of nesting */
.sidebar-nav.expanded .submenu .submenu .submenu .nav-item {
  padding-left: 80px !important; /* Additional indentation for third level */
}

/* Add indentation for fourth level of nesting */
.sidebar-nav.expanded .submenu .submenu .submenu .submenu .nav-item {
  padding-left: 100px !important; /* Additional indentation for fourth level */
}

/* Ensure the active highlighting extends to the edge for all nesting levels */
.sidebar-nav.expanded .submenu .nav-item.active:not(.submenu-trigger):not(.button-item) {
  background-color: #1e4976 !important;
  border-left-color: #007bff;
}

/* Add visual cue for active submenu items */
.sidebar-nav.expanded .submenu .nav-item.active:not(.submenu-trigger):not(.button-item)::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #007bff;
}

/* Match indentation for active items */
.sidebar-nav.expanded .submenu .submenu .nav-item.active:not(.submenu-trigger):not(.button-item) {
  padding-left: 60px !important;
}

.sidebar-nav.expanded .submenu .submenu .submenu .nav-item.active:not(.submenu-trigger):not(.button-item) {
  padding-left: 80px !important;
}

.sidebar-nav.expanded .submenu .submenu .submenu .submenu .nav-item.active:not(.submenu-trigger):not(.button-item) {
  padding-left: 100px !important;
}

/* Submenu in collapsed mode - should be a drawer */
.sidebar-nav.collapsed .submenu {
  position: absolute;
  left: 60px; /* Align with collapsed sidebar width */
  top: 0;
  background-color: #182433;
  border-radius: 4px;
  min-width: 220px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1001; /* Ensure drawer appears above sidebar */
  overflow: visible;
  
  /* Enhanced transitions for smoother animations */
  transition: 
    opacity 100ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 100ms cubic-bezier(0.23, 1, 0.32, 1);
  
  /* Default state - smooth fade in/out */
  opacity: 0;
  transform: translateX(-10px);
  visibility: hidden;
  pointer-events: none;
}

.sidebar-nav.collapsed .menu-section.has-expanded-drawer .submenu {
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
  pointer-events: auto;
}

/* Ensure drawer items are visible and properly styled */
.sidebar-nav.collapsed .menu-section > .submenu .nav-item {
  padding: 0.75rem 1rem;
  background-color: #2a2a2a; /* Set standard background color for all menu items */
  border-left: 4px solid transparent;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative; /* Ensure proper stacking */
}

/* Add indentation for nested submenu items in drawers */
.sidebar-nav.collapsed .menu-section > .submenu .menu-section > .submenu .nav-item {
  padding-left: 2.5rem; /* Increase left padding for nested items */
}

/* Add even more indentation for deeper nested levels if needed */
.sidebar-nav.collapsed .menu-section > .submenu .menu-section > .submenu .menu-section > .submenu .nav-item {
  padding-left: 4rem;
}

.sidebar-nav.collapsed .menu-section > .submenu .nav-item-text {
  display: block !important;
  margin-left: 10px;
  white-space: normal;
  word-wrap: break-word;
  color: #fff;
}

/* Create a small overlap between trigger and drawer to prevent gaps */
.sidebar-nav.collapsed .menu-section>.submenu.expanded::before {
  content: '';
  position: absolute; /* Change back to absolute from fixed */
  top: 0;
  left: -5px;
  width: 5px;
  height: 100%;
  background-color: transparent;
}

/* Positioning drawer class - keep transition and use transform for positioning */
.sidebar-nav.collapsed .menu-section.positioning-drawer > .submenu {
  transition: 
    opacity 180ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 180ms cubic-bezier(0.23, 1, 0.32, 1);
}

/* Hardware acceleration for menu triggers */
.menu-trigger {
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  transform: translateZ(0);
  will-change: background-color;
}

/* Hardware acceleration for submenu items */
.submenu-item, .nav-item {
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  transform: translateZ(0);
  will-change: background-color;
}

/* Hardware acceleration for drawer header */
.sidebar-nav.collapsed .drawer-header {
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
}

/* Optimize drawer header animation */
.sidebar-nav.collapsed .menu-section > .submenu.expanded .drawer-header {
  position: sticky;
  top: 0;
  z-index: 2;
  margin: 0;
  border-bottom: 1px solid #3d8bdf;
  padding: 13px 15px;
  width: 100%;
  box-sizing: border-box;
  transform: translateZ(0);
  opacity: 1;
  
  /* Optimize transition */
  transition: transform 150ms ease-out, opacity 150ms ease-out;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
}

/* Drawer switching optimization - faster transitions when switching between drawers */
.sidebar-nav.collapsed .menu-section.drawer-switching > .submenu {
  transition: 
    opacity 120ms cubic-bezier(0.17, 0.67, 0.83, 0.67),
    transform 120ms cubic-bezier(0.17, 0.67, 0.83, 0.67);
}

.sidebar-nav.collapsed .menu-section.drawer-switching > .submenu.expanded {
  transition: 
    opacity 120ms cubic-bezier(0.17, 0.67, 0.83, 0.67),
    transform 120ms cubic-bezier(0.17, 0.67, 0.83, 0.67);
}

/* Improve transition for parent trigger highlight */
.sidebar-nav.collapsed .menu-section:has(> .submenu.expanded) > .menu-trigger-wrapper > .nav-item.collapsed-trigger {
  background-color: #2d3f50; /* Keep parent highlighted when drawer is open */
  border-bottom: 1px solid #3d8bdf; /* Add bottom border directly to the trigger */
  transition: background-color 150ms ease-out, border-color 150ms ease-out;
}

/* Optimize transition for drawer items */
.sidebar-nav.collapsed .menu-section > .submenu .nav-item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: white;
  text-decoration: none;
  border-left: 2px solid transparent;
  transition: background-color 160ms ease-out, border-left-color 160ms ease-out;
}

.sidebar-nav.collapsed .menu-section > .submenu .nav-item:hover {
  background-color: #2d3f50;
}

.sidebar-nav.collapsed .menu-section > .submenu .nav-item.active {
  background-color: #1e4976;
  border-left-color: #007bff;
}

/* Drawer header should only appear in collapsed mode */
.drawer-header {
  display: none; /* Hide by default */
}

/* Show drawer header only in collapsed mode */
.sidebar-nav.collapsed .drawer-header {
  position: sticky;
  top: 0;
  padding: 13px 15px;
  background-color: #2d3f50;
  border-bottom: 1px solid #3d8bdf;
  font-weight: 600;
  display: flex; /* Override the display: none */
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  z-index: 1;
  
  /* Prevent jitter during transitions */
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
}

/* Improve hover states */
.menu-trigger:hover {
  background-color: #2d3f50;
  transition: background-color 150ms ease-in-out;
}

/* Submenu Trigger Styles */
.nav-item.submenu-trigger {
  font-weight: normal;
}

.sidebar-nav.expanded .nav-item.submenu-trigger {
  font-size: 1em;
}

.nav-item.submenu-trigger .submenu-arrow {
  margin-left: auto;
  transition: transform 0.2s ease-out;
  font-size: 0.8em;
  margin-top: 3px;
}

.nav-item.submenu-trigger .submenu-arrow.expanded {
  transform: rotate(90deg);
}

/* Ensure submenu arrows are visible in collapsed mode drawers */
.sidebar-nav.collapsed .menu-section>.submenu .nav-item.submenu-trigger .submenu-arrow {
  margin-left: auto;
  display: inline-block;
  transition: transform 0.3s ease;
}

/* Make submenu triggers in drawers look clickable */
.sidebar-nav.collapsed .menu-section>.submenu .nav-item.submenu-trigger {
  cursor: pointer;
}

.sidebar-nav.collapsed .menu-section>.submenu .nav-item.submenu-trigger:hover {
  background-color: #2d3f50;
}

/* Ensure nested submenus in collapsed mode work correctly */
.sidebar-nav.collapsed .menu-section>.submenu .menu-section>.submenu {
  position: static;
  box-shadow: none;
  border-left: none;
  max-height: none;
  margin-left: 0;
  padding-left: 0;
  background-color: #2a2a2a;
  height: auto;
  transform: scaleY(1);
  opacity: 1;
  overflow: hidden;
  transition: height 0.15s ease-out, opacity 0.1s ease-out;
  will-change: height, opacity;
}

.sidebar-nav.collapsed .menu-section>.submenu .menu-section>.submenu:not(.expanded) {
  height: 0;
  opacity: 0;
}

.sidebar-nav.collapsed .menu-section>.submenu .menu-section>.submenu.expanded {
  height: auto;
  opacity: 1;
}

.drawer-header-title {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #ffffff;
  width: 100%;
  font-size: 0.91em;
  letter-spacing: 0.5px;
  text-transform: normal;
}

/* Logo Styles */
.sidebar-brand img {
  transition: width 0.15s ease-out, max-width 0.15s ease-out;
  will-change: width, max-width;
}

.full-logo {
  max-width: 150px;
  height: auto;
}

.icon-logo {
  max-width: 30px;
  height: auto;
}

/* Collapsed State Styles */
.sidebar-nav.collapsed {
  width: 60px;
}

.sidebar-nav.collapsed .sidebar-header {
  padding: 0.5rem;
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
}

.sidebar-nav.collapsed .sidebar-brand {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-nav.collapsed .toggle-container {
  position: static;
  margin-left: 2px;
}

.sidebar-nav.collapsed .toggle-button {
  padding: 5px;
  min-width: 20px;
  height: 20px;
}

.sidebar-nav.collapsed .nav-item-text {
  display: none;
}

.sidebar-nav.collapsed .submenu-arrow {
  display: none;
}

.sidebar-nav.collapsed .nav-item.active:not(.submenu-trigger):not(.button-item) {
  display: flex !important;
  /* Override previous display: none */
  background-color: #1e4976;
  border-left-color: #007bff;
}

.sidebar-nav.collapsed .nav-item.active:not(.submenu-trigger):not(.button-item) .nav-item-content {
  justify-content: center;
}

.sidebar-nav.collapsed .nav-item.active:not(.submenu-trigger):not(.button-item) .nav-item-text {
  display: none;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .sidebar-nav.expanded {
    width: 200px;
  }

  .sidebar-nav.collapsed {
    width: 0;
    padding: 0;
  }
}

/* Menu Section Styles */
.menu-section {
  display: flex;
  flex-direction: column;
}

/* Collapsed Trigger Styles - Improve hover target */
.sidebar-nav.collapsed .menu-trigger-wrapper {
  position: relative;
  z-index: 10;
  width: 100%;
  display: block;
}

.sidebar-nav.collapsed .nav-item.collapsed-trigger {
  position: relative;
  transition: background-color 0.1s ease-out;
  z-index: 10;
}

.sidebar-nav.collapsed .nav-item.collapsed-trigger:hover {
  background-color: #2d3f50;
}

/* Highlight for parent trigger when its drawer is being hovered */
.sidebar-nav.collapsed .nav-item.collapsed-trigger.drawer-hovered {
  background-color: #2d3f50;
  transition: background-color 0.1s ease-out;
  position: relative;
  /* Add a subtle glow effect */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

/* Add a subtle indicator to show the connection between trigger and drawer */
.sidebar-nav.collapsed .nav-item.collapsed-trigger.drawer-hovered::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  /* Slightly wider for better visibility */
  height: 100%;
  background-color: #007bff;
}

/* Add blue underline to parent item when drawer is expanded */
.sidebar-nav.collapsed .menu-section:has(> .submenu.expanded) > .menu-trigger-wrapper > .nav-item.collapsed-trigger {
  background-color: #2d3f50; /* Keep parent highlighted when drawer is open */
  border-bottom: 1px solid #3d8bdf; /* Add bottom border directly to the trigger */
}

.sidebar-nav.collapsed .menu-section:has(> .submenu.expanded) > .menu-trigger-wrapper > .nav-item.collapsed-trigger::before {
  content: none; /* Remove the pseudo-element that was creating the line below */
}

/* Selected Item Preview */
.sidebar-nav.collapsed .selected-item-preview {
  position: absolute;
  left: 0;
  width: 50px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1e4976;
  color: white;
  overflow: hidden;
  transition: top 0.15s ease-out;
  will-change: top;
}

.sidebar-nav.collapsed .selected-item-preview .nav-item-text {
  display: none;
}

/* Hide active items in the main sidebar, but not in drawers */
.sidebar-nav.collapsed>.sidebar-content .nav-item.active:not(.submenu-trigger):not(.button-item):not(.sidebar-nav.collapsed .menu-section > .submenu .nav-item.active) {
  display: none;
}

.sidebar-nav.collapsed .menu-section.has-active-child .selected-item-preview {
  top: 0;
}

/* Highlight parent trigger when a submenu item is active in collapsed state */
.sidebar-nav.collapsed .menu-section:has(.submenu .nav-item.active)>.menu-trigger-wrapper>.nav-item.collapsed-trigger {
  background-color: #2d3f50;
  position: relative;
}

.sidebar-nav.collapsed .menu-section:has(.submenu .nav-item.active)>.menu-trigger-wrapper>.nav-item.collapsed-trigger::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  height: 100%;
  background-color: #007bff;
}

/* Ensure nested submenus don't appear as drawers */
.sidebar-nav.collapsed .menu-section>.submenu .submenu {
  position: static;
  box-shadow: none;
  border-left: none;
  margin-left: 1rem;
  padding-left: 0.5rem;
  background-color: #2a2a2a;
}

/* Ensure drawer header is never shown in expanded mode */
.sidebar-nav.expanded .drawer-header {
  display: none !important;
}


/* Explicitly hide drawer headers in nested submenus */
.sidebar-nav.collapsed .menu-section > .submenu .nested-submenu .drawer-header,
.sidebar-nav.collapsed .menu-section > .submenu .menu-section > .submenu .drawer-header {
  display: none !important;
}

/* Prevent scrollbar flicker during drawer positioning */
body.positioning-drawer {
  overflow-y: auto !important;
}

/* Add a container class to prevent scrollbar jumps */
.app-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 1; /* Create new stacking context */
}

/* Remove the body styles that were causing scrollbar issues */
body.positioning-drawer {
  overflow-y: auto !important;
}

/* Indentation based on nesting level class, more targeted than using submenu nesting */
.sidebar-nav.expanded .nav-item.nesting-level-1 {
  padding-left: 40px !important;
}

.sidebar-nav.expanded .nav-item.nesting-level-2 {
  padding-left: 60px !important;
}

.sidebar-nav.expanded .nav-item.nesting-level-3 {
  padding-left: 80px !important;
}

.sidebar-nav.expanded .nav-item.nesting-level-4 {
  padding-left: 100px !important;
}

.sidebar-nav.expanded .nav-item.nesting-level-5 {
  padding-left: 120px !important;
}

/* Active items indentation based on nesting level */
.sidebar-nav.expanded .nav-item.active.nesting-level-1:not(.submenu-trigger):not(.button-item) {
  padding-left: 40px !important;
}

.sidebar-nav.expanded .nav-item.active.nesting-level-2:not(.submenu-trigger):not(.button-item) {
  padding-left: 60px !important;
}

.sidebar-nav.expanded .nav-item.active.nesting-level-3:not(.submenu-trigger):not(.button-item) {
  padding-left: 80px !important;
}

.sidebar-nav.expanded .nav-item.active.nesting-level-4:not(.submenu-trigger):not(.button-item) {
  padding-left: 100px !important;
}

.sidebar-nav.expanded .nav-item.active.nesting-level-5:not(.submenu-trigger):not(.button-item) {
  padding-left: 120px !important;
}

/* Indentation for nested items in collapsed sidebar drawers */
.sidebar-nav.collapsed .menu-section > .submenu .nav-item.nesting-level-1 {
  padding-left: 1rem !important; /* Base indentation for first level in drawer */
}

.sidebar-nav.collapsed .menu-section > .submenu .nav-item.nesting-level-2 {
  padding-left: 2rem !important; /* Additional indentation for second level */
}

.sidebar-nav.collapsed .menu-section > .submenu .nav-item.nesting-level-3 {
  padding-left: 3rem !important; /* Additional indentation for third level */
}

.sidebar-nav.collapsed .menu-section > .submenu .nav-item.nesting-level-4 {
  padding-left: 4rem !important; /* Additional indentation for fourth level */
}

.sidebar-nav.collapsed .menu-section > .submenu .nav-item.nesting-level-5 {
  padding-left: 5rem !important; /* Additional indentation for fifth level */
}

/* Nested submenu sections in collapsed view */
.sidebar-nav.collapsed .menu-section > .submenu .menu-section {
  width: 100%;
  position: relative;
}

/* Nested submenu containers in collapsed view */
.sidebar-nav.collapsed .menu-section > .submenu .menu-section > .submenu {
  position: static !important;
  width: 100% !important;
  box-shadow: none !important;
  border-left: none !important;
  background-color: #2a2a2a !important; /* Match the standard menu item background color */
  margin-left: 0 !important;
  left: 0 !important;
  opacity: 1 !important;
  visibility: visible !important;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

/* Expanded nested submenu in collapsed view */
.sidebar-nav.collapsed .menu-section > .submenu .menu-section > .submenu.expanded {
  max-height: 500px; /* Large enough to accommodate content */
}

/* Submenu triggers in collapsed view */
.sidebar-nav.collapsed .menu-section > .submenu .nav-item.submenu-trigger {
  background-color: #2a2a2a; /* Match the standard menu item background color */
}

/* Expanded submenu trigger in collapsed view */
.sidebar-nav.collapsed .menu-section > .submenu .menu-section.has-expanded-drawer > .menu-trigger-wrapper > .nav-item.submenu-trigger {
  background-color: #2d3f50 !important; /* Match hover color for expanded triggers */
}

/* Visual indicator for submenu arrow in collapsed view */
.sidebar-nav.collapsed .menu-section > .submenu .nav-item.submenu-trigger .submenu-arrow {
  transform: rotate(0deg);
  transition: transform 0.2s ease-out;
}

.sidebar-nav.collapsed .menu-section > .submenu .nav-item.submenu-trigger .submenu-arrow.expanded {
  transform: rotate(90deg);
}

/* Additional styles for nested submenus in collapsed view */
.sidebar-nav.collapsed .menu-section > .submenu .nested-submenu {
  background-color: #2a2a2a !important; /* Match the standard menu item background color */
  padding: 0 !important;
  margin: 0 !important;
}

.sidebar-nav.collapsed .menu-section > .submenu .nested-submenu.expanded {
  padding: 0.25rem 0 !important;
}

/* Nested section headers in collapsed view */
.sidebar-nav.collapsed .menu-section > .submenu .menu-section > .menu-trigger-wrapper .nav-item.submenu-trigger {
  font-size: 0.91em !important;
  letter-spacing: 0.5px !important;
  text-transform: normal;
  background-color: #2a2a2a !important; /* Match the standard menu item background color */
  padding: 0.6rem 1rem !important;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

/* Expanded nested section headers in collapsed view */
.sidebar-nav.collapsed .menu-section > .submenu .menu-section.has-expanded-drawer > .menu-trigger-wrapper .nav-item.submenu-trigger {
  background-color: #2d3f50 !important; /* Match hover color for expanded nested triggers */
}

/* Fix for nested submenu items vertical spacing */
.sidebar-nav.collapsed .menu-section > .submenu .menu-section > .submenu .nav-item:not(.submenu-trigger) {
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important;
  font-size: 0.95em !important;
}