/* App Layout */
.app-container {
  display: flex;
  height: 100vh;
  overflow: hidden; /* Prevent overall page scroll */
  background-color: var(--theme-background);
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: var(--theme-background);
  position: relative;
  padding: 0;
}

.content-area {
  flex: 1;
  /* padding: 20px; */
  overflow: hidden;
  background-color: var(--theme-background);
}

/* Navigation Links */
.nav-links {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}

.nav-link-card {
  text-align: center;
  padding: 15px;
  border-radius: 8px;
  background-color: var(--theme-secondary-background);
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  color: inherit;
  display: block;
}

.nav-link-card:hover {
  transform: translateY(-5px);
  background-color: var(--theme-secondary-background);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.nav-link-card h3 {
  margin-top: 10px;
  font-size: 1rem;
  color: #fff;
}

.nav-link {
  color: #fff;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.nav-link:hover {
  background-color: #3a3a3a;
  color: #fff;
}

/* Badge Styles */
.nav-link span {
  background-color: #dc3545;
  color: white;
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 0.8rem;
  margin-left: 5px;
}

.nav-link-card img {
  width: 64px;
  height: 64px;
  margin-bottom: 10px;
}

/* Footer */
.footer {
  margin-top: auto;
  margin-left: -21px; /* Extend to match sidebar edge exactly (-20px content padding + -1px to match sidebar) */
  width: calc(100% + 21px); /* Compensate for the full negative margin */
  background-color: var(--theme-background);
  border-top: 1px solid var(--theme-border);
  transition: all 0.2s ease-out;
  position: relative;
  will-change: height, opacity;
  min-height: 3rem;
  padding: 0;
}

.footer.collapsed {
  min-height: 0;
}

.footer.collapsed .footer-content {
  height: 0;
  padding: 0;
  overflow: hidden;
  opacity: 0;
}

.footer-content {
  padding: 1rem 20px 1rem 21px; /* Extra 1px padding on left to account for the additional margin */
  height: auto;
  opacity: 1;
  transition: all 0.2s ease-out;
}

.footer-toggle {
  position: absolute;
  top: -24px;
  right: 20px;
  z-index: 100;
  background-color: var(--theme-background);
  border: 1px solid var(--theme-border);
  border-bottom: none;
  color: var(--theme-text);
  padding: 4px 12px;
  cursor: pointer;
  border-radius: 4px 4px 0 0;
  transition: background-color 0.2s ease-out;
  display: flex;
  align-items: center;
  gap: 4px;
  height: 24px;
  font-size: 0.875rem;
}

.footer-toggle:hover {
  background-color: var(--theme-secondary-background);
}

.footer-toggle .fa {
  transition: transform 0.2s ease-out;
  transform: rotate(180deg); /* Start rotated to point down */
}

.footer-toggle.collapsed .fa {
  transform: rotate(0deg); /* Return to original position (pointing up) */
}

.ticker-container {
  overflow: hidden;
  white-space: nowrap;
  min-height: 2rem;
  display: flex;
  align-items: center;
}

/* Theme Styles */
body.dark-theme {
  background-color: var(--theme-background);
  color: var(--theme-text);
}

body.light-theme {
  background-color: var(--theme-background);
  color: var(--theme-text);
}
